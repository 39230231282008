<template>
  <div class="location-content">
    <div class="main-map-pop" id="container">
      <div class="map-popover" v-if="isLookForCar">
        <car-info-popover :positionInfo="mapPosition[0]" ref="carInfoPopover"></car-info-popover>
      </div>
    </div>
    <!-- 地图模式 -->
    <div v-show="isLookForCar" class="common main-map" id="container_query">
      <!-- 实时位置--区域查车 -->
      <div class="area-tab">
        <el-dropdown class="area-search" placement="bottom" @command="handleCommand">
          <span class="area-search-car">区域查车</span>
          <el-dropdown-menu slot="dropdown" style="margin-top:30px; font-size: 12px">
            <el-dropdown-item command=1>地图拉框查询</el-dropdown-item>
            <el-dropdown-item command=2>预设区域查询</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 预设区域查询的dialog -->
        <el-dialog
          title="预设区域查询"
          :visible.sync="mapVisible_3"
          append-to-body
          width="70%"
          top="10vh"
          @open="newAmap_3"
          center>
          <div style="border-top: 1px solid rgba(232,232,232,1);"></div>
          <div class="main">
            <!-- 左边列表部分 -->
            <div class="main-right-list main-box-shadow">
              <span>
                <ayl-table :table="dialogTable"/>
              </span>
            </div>
            <!-- 右边地图部分 -->
            <div class="main-right-map main-box-shadow" style="margin-bottom: 20px;">
              <div style="padding: 10px; display: inline-block;width:100%; ">
                <span style="margin-right: 4px;">设置区域类型：</span>
                <el-radio-group v-model="radio" @change="changeRegionRadio">
                  <el-radio :label=0>矩形区域</el-radio>
                  <el-radio :label=1>圆形区域</el-radio>
                </el-radio-group>
                <span style="margin-left: 10%">区域名称：</span>
                <el-input style="width: 30%" maxlength="32" v-model.trim="regionName" placeholder="请输入区域名称" clearable></el-input>
              </div>
              <span>
                <div id="container_region" style="width: 100%; height: 450px;"></div>
              </span>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="addRegion">增加区域</el-button>
            <el-button @click="mapVisible_3 = false">取 消</el-button>
          </span>
        </el-dialog>

        <span class="area-search-car" style="margin-left: 10px" @click="lookForCar">定时定位查车</span>
      </div>
    </div>
    <!-- 定时定位查车 -->
    <div v-show="!isLookForCar" class="main-map">
      <div style="border: 1px solid rgba(232,232,232,1);height: 1px;width: 100%;"></div>
      <!-- 右边表格数据 -->
      <div class="lookfor-car-main-table main-box-shadow">
        <el-scrollbar style="height: 100%;overflow-x:hidden;">
          <div style="width: 100%; height: 100%;">
            <div style="padding-bottom: 16px">
              <span class="sp1">查询条件</span>
              <span class="sp2" @click="searchArea">查询</span>
            </div>
            <!-- 区域表格list -->
            <div style="margin-top: 16px;">
              <ayl-table :table="table" style="width: 100%"/>
            </div>
            <!-- 增加区域按钮 -->
            <div class="addArea" @click="addAreaBtn">
              <span class="a">+</span><span class="d">增加区域</span>
            </div>
            <el-dialog
              title="增加区域"
              :visible.sync="centerMapDialogVisible"
              width="50%"
              @open="newAmap_2"
              center>
              <div style="border-top: 1px solid rgba(232,232,232,1); margin-bottom: 8px;"></div>
              <div>
                <span style="margin-right: 12px;">设置区域类型</span>
                <el-radio-group v-model="radio" @change="changeRadio">
                  <el-radio :label=0>矩形区域</el-radio>
                  <el-radio :label=1>圆形区域</el-radio>
                </el-radio-group>
              </div>
              <div class="block" style="padding: 12px 0px;">
                <span class="demonstration" style="margin-right: 12px">起止时间</span>
                <el-date-picker
                  v-model="value"
                  type="datetimerange"
                  value-format="timestamp"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  align="right">
                </el-date-picker>
              </div>
              <span>
                <div id="container_area" style="width: 100%; height: 486px"></div>
              </span>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addArea">确 定</el-button>
                <el-button @click="centerMapDialogVisible = false">取 消</el-button>
              </span>
            </el-dialog>

            <!-- 查询结果 -->
            <div class="result-title">
              <span class="sp1">查询结果</span>
              <span class="sp2" @click="dialogExport=true">导出</span>
            </div>
            <!--导出-->
            <el-dialog title="选择导出的数据" :visible.sync="dialogExport" width="540px" center>
              <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
              <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
                <el-checkbox :label="item.index" v-for="(item, idx) in checkList" :key="idx">{{item.value}}</el-checkbox>
              </el-checkbox-group>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="exportExcel" :loading="btnLoading">确 定</el-button>
                <el-button @click="dialogExport=false">取 消</el-button>
              </span>
            </el-dialog>
            <!-- 区域表格list -->
            <div style="margin-top: 16px;">
              <ayl-table :table="resultTable" @selection-change="selectChange"/>
            </div>
            <!-- 发送消息+返回 -->
            <div class="result-title" style="text-align: center;">
              <el-button type="primary" @click="visibleMap">发送消息</el-button>
              <el-button plain style="margin-left: 30px;" @click="backSubPage">返回</el-button>
            </div>
            <!-- 发送消息的提示语 -->
            <el-dialog
              title="发送消息"
              :visible.sync="centerDialogVisible"
              width="30%"
              top="25vh"
              center>
              <el-input v-model.trim="inputMsg" type="textarea" :rows="5" placeholder="请输入内容" maxlength="64"></el-input>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="enterOK">确 定</el-button>
                <el-button @click="centerDialogVisible = false">取 消</el-button>
              </span>
            </el-dialog>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
  import CarInfoPopover from './car-Info-Popover'
  const LineIcon = require('../../../../assets/images/map-icon (1).png')
  const offLineIcon = require('../../../../assets/images/map-icon (2).png')
  const onLineIcon = require('../../../../assets/images/map-icon (3).png')
  export default {
    components: {
      CarInfoPopover,
    },
    props: {
      carList: {
        type: Array,
        default: function () {
          return []
        },
      },
      deviceID:{
        type: String,
        default: '',
      }
    },
    data () {
      const vm = this
      return {
        mapPosition: [],
        map_2: null,
        center: null,
        isLookForCar: true,
        value: [],
        checkList: [],
        checkIndex: [],
        ampArray: [],
        btnLoading: false,
        dialogExport: false,
        filterText: '',
        plateNumberList: [],
        centerDialogVisible: false,
        centerMapDialogVisible: false,
        inputMsg: '',
        radio: null,
        map_1: null,
        mouseTool: null,
        addAreaMouseTool: null,
        serachResultData: null,
        mapVisible_3: false,// 新增预设区域查询
        regionName: '',
        mouseRegionTool: null,
        map_3: null,
        rechQueryMouseTool: null,
        infoWindow: null,
        overlays: [],
        markers: [],
        timer: null,
        OtherTimer: null,
        checkStatus: false,
        table: {
          api: vm.$api.searchByLocationAndTime,
          tableHeight: '167px',
          hidePagination: true,
          columns: [
            {
              title: '区域类型',
              key: 'regionType',
            }, {
              title: '经纬度',
              key: 'coordinate',
            }, {
              title: '开始时间',
              key: 'startTime',
              filter: 'str2ymd'
            }, {
              title: '结束时间',
              key: 'endTime',
              filter: 'str2ymd'
            }, {
            title: '操作',
            width: '155px',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class:{
                    'table-view' : true,
                  },
                  on: {
                    click: vm.search.bind(this, ctx.row)
                  }
                }, '查询'),
                h('span', {
                  class: {
                    'table-delete': true,
                    'table-view-edit': true
                  },
                  on: {
                    click: vm.delete.bind(this, ctx.row)
                  }
                }, '删除')
              ])
            }
          }]
        },
        /** 预设区域table记录 */
        dialogTable: {
          api: vm.$api.searchDefaultRegion,
          tableHeight: "500px",
          hidePagination: true,
          query: {
            regionBelong: 0
          },
          columns: [{
              title: '区域名称',
              key: 'regionName',
            }, {
              title: '区域类型',
              key: 'regionType',
            }, {
              title: '设置时间',
              key: 'gmtCreate',
              filter: 'str2ymd'
            }, {
            title: '操作',
            width: '155px',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class:{
                    'table-view' : true,
                  },
                  on: {
                    click: vm.searchRegion.bind(this, ctx.row)
                  }
                }, '查询'),
                h('span', {
                  class: {
                    'table-delete': true,
                    'table-view-edit' : true
                  },
                  on: {
                    click: vm.deleteRegion.bind(this, ctx.row)
                  }
                }, '删除')
              ])
            }
          }
          ]
        },
        resultTable: {
          api: vm.$api.searchByRegionAndTime,
          query: {
            deviceIdList: [],
            regionIdList:[],
          },
          tableHeight: '237px',
          hidePagination: true,
          columns: [
            // 需要多选
            {
              type: 'selection',
              // width: '100px'
            }, {
              title: '所属公司',
              key: 'company',
            }, {
              title: '车牌号',
              key: 'plateNumber',
            }, {
              title: '驾驶员姓名',
              key: 'driverName',
            }, {
              title: '时间',
              key: 'time',
              filter: 'str2ymd',
            }, {
              title: '位置',
              key: 'address',
            }]
        },
        locationCarData: [],
      }
    },
    watch: {
      carList: {
        async handler (newName) {
          // 把数据都清空
          if (newName.length === 0) {
            // this.map_1.remove(this.mapMarker)
            this.map_1.remove(this.markers)
            // this.map_1.clearMap()
            this.clear()
            this.allVideoUrl = []
            this.mapPosition = []
            this.$refs.carInfoPopover.isShow = false
            return
          }
          // 调用位置方法
          await this.triggerData()
        }
      }
    },
    methods: {
      // 地图操作
      async triggerData () {
        
        // 请求车辆位置信息
        var lastCar = this.carList.length -1
        console.log('lastCar:' + lastCar)
        if(this.deviceID !== "" && JSON.stringify(this.carList).indexOf(this.deviceID) !== -1){
          console.log('父组件传值: ' + this.deviceID)
          this.mapPosition = await this.$api.GetRealTimeCarLocation({
            deviceIds: [this.deviceID]
          })
        }else{
          console.log('取组件最后一个')
          this.mapPosition = await this.$api.GetRealTimeCarLocation({
            deviceIds: [this.carList[lastCar].device]
          })
        }

        /**
         * 1. 获取所有的设备id
         * 2. 将设备id传递绘制并监听
         */
        console.log(this.carList)
        let deviceIds = []
        this.carList.forEach((item) => {
          deviceIds.push(item.device)
        })
        console.log(deviceIds)
        this.getClickCar(deviceIds)
        
        this.$refs.carInfoPopover.isShow = true
        console.log('设置显示...')
      },
      /**
       * 点击具体设备
       */
      async getClickCar(deviceIds){
        this.map_1.remove(this.markers)
        this.map_1.clearMap()
        // this.clear()
        let pointsList = await this.$api.GetRealTimeCarLocation({deviceIds: deviceIds})
        let arr_1 = []
        let arr_2 = []
        let arr_3 = []
        for(let y = 0; y < pointsList.length; y++){
          arr_1.push(pointsList[y].id)
          arr_2.push(pointsList[y].position)
          arr_3.push(pointsList[y].deviceStatus)
        }
        this.showClickMarkerInfo(pointsList, arr_2, arr_3)
      },
      /**
       * 点击设备绘制图表
       */
      showClickMarkerInfo(pointsList, lnglats, info){
        console.log('s-info: ' + JSON.stringify(info))
        var map = this.map_1;
        var marker = null
        for (var i = 0, marker; i < lnglats.length; i++) {
          try {
            console.log('坐标点集合: ' + lnglats[i][0])
            if(lnglats[i][0] !== null){
              marker = new AMap.Marker({
              icon: require('../../../../assets/images/map-icon (2).png'),
              position: lnglats[i],
              map: map
              });
            }else{
              continue
            }
          } catch (e) {
            console.log(e)
          }
            
            marker.content = pointsList[i];
            marker.on('click', (e)=>{
                console.log(this.mapPosition[0])
                // marker.setIcon(require('../../../../assets/images/map-icon (1).png'))
                this.$set(this,'mapPosition',[e.target.content])
            });
            // marker.emit('click', {target: marker});
            this.markers.push(marker);
        }
        map.setFitView();
      },

      // 初始化地图
      initAmap () {
        // eslint-disable-next-line no-undef
        this.map_1 = new AMap.Map('container_query', {
          center: this.center,
          resizeEnable: true,
          zoom: 11,
        });
        // eslint-disable-next-line no-undef
        this.rechQueryMouseTool = new AMap.MouseTool(this.map_1);
      },
      // 新增区域地图初始化
      initAddAreaAmap(){
        // eslint-disable-next-line no-undef
        this.map_2 = new AMap.Map('container_area', {
          center: this.center,
          resizeEnable: true,
          zoom: 11,
        });
        // eslint-disable-next-line no-undef
        this.addAreaMouseTool = new AMap.MouseTool(this.map_2);
      },
      newAmap_2() {
        this.$nextTick(() => {
            this.initAddAreaAmap()
        });
      },
      clear(){
          // this.map.remove(this.overlays);
          // this.overlays=[];
          console.log('清除缓存...')
          this.addAreaMouseTool.close(true);
          this.mouseRegionTool.close(true);
          this.rechQueryMouseTool.close(true);
          // eslint-disable-next-line no-undef
          this.rechQueryMouseTool = new AMap.MouseTool(this.map_1);
          // eslint-disable-next-line no-undef
          this.addAreaMouseTool = new AMap.MouseTool(this.map_2);
          // eslint-disable-next-line no-undef
          this.mouseRegionTool = new AMap.MouseTool(this.map_3);

          this.$refs.carInfoPopover.isShow = false
          this.mapPosition = []
      },
      /** 区域查车  */
      handleCommand(value){
        /** 清除所有的状态 */
        clearInterval(this.timer)
        clearInterval(this.OtherTimer)
        this.clear();
        this.map_1.clearMap()
        this.echoTree([])
        // this.table.query.contentList = []

        console.log(value)
        if(value === "1"){
          this.drawRectQuery('rectangle')
        }else if(value === "2"){
          this.showHistorySearch()
        }
      },
      /** ---------------------地图拉框查询-------------------start--- */
      /**
       * 地图拉框查询
       */
      drawRectQuery(gtype){
        this.clear();
        this.rechQueryMouseTool.rectangle({
            fillColor:'#00b0ff',
            strokeColor:'#80d8ff'
        })
        this.getMapRectInfo(this.rechQueryMouseTool, gtype)
      },
      getMapRectInfo(mouseTool, gtype){
        const vm = this;
        this.map_1.plugin(["AMap.MouseTool"],function(){
          // eslint-disable-next-line no-undef
          AMap.event.addListener(mouseTool,'draw',function(e){
            // vm.mouseTool.close(true)
            // vm.mouseTool = new AMap.MouseTool(vm.map)
            switch(gtype){
              case 'rectangle':{
                /**
                 * 基本思路： 过滤坐标点e.obj.getPath().length 长度为1 的情况，长度为1时一定是左键点击或者右键点击的坐标点，
                 * 为4不一定是矩形，也有可能的是微小的矩形查询无意义
                 */
                let points = []
                if(e.obj.getPath().length === 4){
                  for(let i = 0; i<e.obj.getPath().length; i++){
                    points.push(e.obj.getPath()[i].lng, e.obj.getPath()[i].lat)
                  }
                  vm.getCarWithRegion(points)

                  if(vm.timer){
                    clearInterval(vm.timer)
                  }
                  vm.timer = setInterval(() => {
                    vm.getCarWithRegion(points)
                  }, 5000)
                }
              }
            }
          })
        })
      },
      /**
       * 根据坐标点获取矩形区域的车辆信息
       */
      async getCarWithRegion(points){
        console.log('--getCarWithRegion')
        this.map_1.remove(this.markers)
        // if(this.infoWindow !== null){
        //   this.infoWindow.close()
        // }
        let pointsList = this.pointsList = await this.$api.getCarWithRegion({points: points})
        console.log(pointsList)

        if(!pointsList.length > 0){
          this.$notify.success({
            title: '查询成功',
            message: '该区域无车辆信息'
          })
        }

        this.mapPosition = pointsList
        if(pointsList.length > 0){
          this.$refs.carInfoPopover.isShow = true
        }else{
          this.$refs.carInfoPopover.isShow = false
        }
        

        let arr_1 = []
        let arr_2 = []
        let arr_3 = []
        for(let y = 0; y < pointsList.length; y++){
          arr_1.push(pointsList[y].id)
          arr_2.push(pointsList[y].position)
          arr_3.push(pointsList[y].deviceStatus)
        }
        this.echoTree(arr_1)
        this.checkStatus = true
        this.checked()
        this.showMarkerInfo(pointsList, arr_2, arr_3)
      },
      /** 当复选框被点击的时候触发 */
      checked(value, node){
        // console.log('check-value: ' + JSON.stringify(this.$refs.tree.getCheckedNodes(true, false)) + '------------')
        console.log('this.checkStatus: ' + this.checkStatus)
        // 获取所有选中叶子节点的额数据
        let arr_1 = []
        let arr_2 = []
        // arr_1 = this.$refs.tree.getCheckedNodes(true, false)
        // console.log('----' + JSON.stringify(this.$refs.tree.getCheckedNodes(true, false)))
        arr_1.forEach((item, index, arr) => {
          arr[index] = item.label.trim().substring(0,7)
          arr_2.push(item.device)
        })
        /** 查询实时车辆位置 */
        if(this.checkStatus !== true){
          this.showCarGraph(arr_2)
        }else{
          if(this.OtherTimer){
            clearInterval(this.OtherTimer)
          }
        }
      },
      /** 获取车辆的实时位置 */
      showCarGraph(deviceIds){
        this.getCheckCarWithRegion(deviceIds)
        if(this.OtherTimer){
          clearInterval(this.OtherTimer)
        }
        this.OtherTimer = setInterval(() => {
          console.log('666666')
          this.getCheckCarWithRegion(deviceIds)
        }, 5000)
      },
      /**
       * 根据坐标点获取矩形区域的车辆信息
       */
      async getCheckCarWithRegion(deviceIds){
        this.map_1.remove(this.markers)
        // if(this.infoWindow !== null){
        //   this.infoWindow.close()
        // }
        let pointsList = await this.$api.GetRealTimeCarLocation({deviceIds: deviceIds})

        // this.mapPosition = pointsList
        // this.$refs.carInfoPopover.isShow = true

        let arr_1 = []
        let arr_2 = []
        let arr_3 = []
        for(let y = 0; y < pointsList.length; y++){
          arr_1.push(pointsList[y].id)
          arr_2.push(pointsList[y].position)
          arr_3.push(pointsList[y].deviceStatus)
        }
        this.showMarkerInfo(pointsList, arr_2, arr_3)
      },
      /** 回显tree节点 */
      echoTree(arr_1){
        console.log('传值...' + arr_1)
        this.$emit('check-change', arr_1)
      },
      showMarkerInfo(pointsList, lnglats, info){
        //初始化地图对象，加载地图
        // var map = this.map = new AMap.Map("container", {resizeEnable: true});
        console.log('info: ' + JSON.stringify(info))
        var map = this.map_1;
        // var infoWindow = this.infoWindow = new AMap.InfoWindow({offset: new AMap.Pixel(18, -16), anchor: 'middle-left',});
        var marker = null
        for (var i = 0, marker; i < lnglats.length; i++) {
            if(info[i].indexOf("在线") != -1){
              console.log('在线')
              marker = new AMap.Marker({
              icon: require('../../../../assets/images/map-icon (3).png'),
              position: lnglats[i],
              map: map
            });
            }else{
                console.log('离线')
                marker = new AMap.Marker({
                icon: require('../../../../assets/images/map-icon (1).png'),
                position: lnglats[i],
                map: map
              });
            }
            marker.content = pointsList[i];
            marker.on('click', (e)=>{
                console.log(this.mapPosition[0])
                // marker.setIcon(require('../../../../assets/images/map-icon (2).png'))
                this.$set(this,'mapPosition',[e.target.content])
            });
            marker.emit('click', {target: marker});
            this.markers.push(marker);
            // // 创建覆盖物群组，并将 marker 传给 OverlayGroup
            // overlays = new AMap.OverlayGroup(markers);
            // map.add(overlays);
        }
        // function markerClick(e) {
        //   // this.mapPosition.push(e.target.content)
        //   // console.log('this.mapPosition: ' + JSON.stringify(this.mapPosition[0]))
        //   // infoWindow.setContent(e.target.content);
        //   // infoWindow.open(map, e.target.getPosition());
        // }
        map.setFitView();
      },
      /** ---------------------地图拉框查询-------------------end--- */
      
      /**
       * 定时定位查车
       */
      lookForCar(){
        console.log('开发中...')
        this.isLookForCar = false
        clearInterval(this.timer)
        clearInterval(this.OtherTimer)
        this.clear();
        this.map_1.clearMap()
        this.echoTree([])
        this.$refs.carInfoPopover.isShow = false
      },
      /**
       * 返回地图模式--实时位置监控
       */
      backSubPage(){
        this.isLookForCar = true
      },
      /**
       * 查询区域列表
       */
      async searchArea(){
        /** 
         * 预先获取列表中所有的deviceID
         */
        // this.resultTable.query.regionIdList = 
        let arr_2 = []
        arr_2 =  this.serachResultData = await this.$api.searchByLocationAndTime({})
        this.resultTable.query.regionIdList = []
        console.log(this.serachResultData.length)
        arr_2.forEach((item, index, arr_2) => {
          this.resultTable.query.regionIdList.push(item.regionId)
        })
        await this.$search(this.resultTable)
      },
      /**
       * 显示地图
       */
      visibleMap(){
        if(this.plateNumberList.length === 0){
          this.$message.error('请勾选要发送消息的车辆')
          return
        }
        this.centerDialogVisible = !this.centerDialogVisible
      },
      /** 新增区域 */
      async addArea(){
        if(this.radio == null){
          this.$message.error('请选择区域类型');
          return
        }
        if(this.value.length === 0){
          this.$message.error('请选择时间');
          return
        }
        if(this.ampArray.length == 0){
          this.$message.error('请选择一个区域');
          return
        }
        await this.$api.insertRegionInfo({
          insertType: 1,
          regionInfoVOIOV: {
            regionName: '',
            regionArgs: this.ampArray,
            regionType: this.radio === 0 ? '矩形' : '圆形',
            startTime: this.value[0],
            endTime: this.value[1],
            regionBelong: 0
          }
        })
        this.centerMapDialogVisible = false
        this.$notify({
          title: '成功',
          message: '新增区域成功',
          type: 'success'
        });
        this.onSuccess()
      },
      async onSuccess(){
        await this.$search(this.table)
      },
      isIndeterminate(){},
      handleCheckAllChange(){},
      checkAll(){},
      click(){},
      handleCheckedCitiesChange(){},
      /** 增加区域 */
      addAreaBtn(){
        this.centerMapDialogVisible = !this.centerMapDialogVisible
        this.radio = null
      },
      changeRadio(val){
        this.ampArray = []
        if(val === 0){
          console.log('1. 选择了矩形')
          this.draw('rectangle')
        }else{
          this.draw('circle')
        }
      },
      draw(gtype){
        this.clear();
        if(gtype === "rectangle"){
          this.addAreaMouseTool.rectangle({
              fillColor:'#00b0ff',
              strokeColor:'#80d8ff'
          })
        }else{
          this.addAreaMouseTool.circle({
            fillColor:'#00b0ff',
            strokeColor:'#80d8ff'
          })
        }
        this.getMapInfo(this.addAreaMouseTool, gtype)
      },
      getMapInfo(addAreaMouseTool, gtype){
        const vm = this;
        console.log('绘制...')
        this.map_2.plugin(["AMap.MouseTool"],function(){
          // eslint-disable-next-line no-undef
          AMap.event.addListener(addAreaMouseTool,'draw',function(e){
            switch(gtype){
              case 'rectangle':{
                // console.log('rectangle')
                /**
                 * 基本思路： 过滤坐标点e.obj.getPath().length 长度为1 的情况，长度为1时一定是左键点击或者右键点击的坐标点，
                 * 为4不一定是矩形，也有可能的是微小的矩形查询无意义
                 */
                vm.ampArray = []
                if(e.obj.getPath().length === 4){
                  for(let i = 0; i<e.obj.getPath().length; i++){
                    vm.ampArray.push(e.obj.getPath()[i].lng, e.obj.getPath()[i].lat)
                  }
                }
                console.log(vm.ampArray)
                break;
              }
              case 'circle':{
                // console.log('circle')
                vm.ampArray = []
                vm.ampArray.push(e.obj.getCenter().lng)
                vm.ampArray.push(e.obj.getCenter().lat)
                vm.ampArray.push(e.obj.getRadius())
                console.log(vm.ampArray)
                break;
              }
            }
          })
        })
      },

      /**
       * 发送消息
       */
      async enterOK(){
        /** 发送消息 */
        await this.$api.sendMessage({
          plateNumberList: this.plateNumberList,
          text: this.inputMsg,
        })
        this.centerDialogVisible = false
        this.$notify.success({
        title: '发送消息',
        message: '发送消息成功'
      })
      },
      async delete(val){
        console.log('val: ' + JSON.stringify(val))
        await this.$confirm(`您确认删除【${val.regionType}】吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        // 删除保险信息
        await this.$api.DeleteRegionInfo({
          regionIdList: [val.regionId]
        })
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        });
        await this.$search(this.table)
      },
      async search(val){
        this.resultTable.query.regionIdList = []
        this.resultTable.query.regionIdList.push(val.regionId)
        await this.$search(this.resultTable)
      },
      exportExcel(){},
      /** 点击列表的回调 */
      selectChange(data){
        if (data.length===0) {
          this.plateNumberList = []
          return
        }
        this.plateNumberList = data.map(e=>{
          return e.plateNumber
        })
      },
      /** 手动点击搜索 */
      clickFilterText(){
        // this.$refs.tree.filter(this.filterText);
      },

      /** ---------------------预设区域查询的方法-------------------start--- */
      newAmap_3(){
        this.$nextTick(() => {
            this.initRegionAmap()
        });
      },
      initRegionAmap(){
        this.map_3 = new AMap.Map('container_region', {
          center: this.center,
          resizeEnable: true,
          zoom: 11,
        });
        this.mouseRegionTool = new AMap.MouseTool(this.map_3);
      },
      changeRegionRadio(val){
        this.ampArray = []
        if(val === 0){
          console.log('1. 选择了矩形')
          this.drawRegion('rectangle')
        }else{
          this.drawRegion('circle')
        }
      },
      drawRegion(gtype){
        this.clear();
        if(gtype === "rectangle"){
          this.mouseRegionTool.rectangle({
              fillColor:'#00b0ff',
              strokeColor:'#80d8ff'
          })
        }else{
          this.mouseRegionTool.circle({
            fillColor:'#00b0ff',
            strokeColor:'#80d8ff'
          })
        }
        this.getRegionMapInfo(this.mouseRegionTool, gtype)
      },
      getRegionMapInfo(mouseRegionTool, gtype){
        const vm = this;
        console.log('新增预设区域绘制...')
        this.map_3.plugin(["AMap.MouseTool"],function(){
          // eslint-disable-next-line no-undef
          AMap.event.addListener(mouseRegionTool,'draw',function(e){
            switch(gtype){
              case 'rectangle':{
                // console.log('rectangle')
                /**
                 * 基本思路： 过滤坐标点e.obj.getPath().length 长度为1 的情况，长度为1时一定是左键点击或者右键点击的坐标点，
                 * 为4不一定是矩形，也有可能的是微小的矩形查询无意义
                 */
                vm.ampArray = []
                if(e.obj.getPath().length === 4){
                  for(let i = 0; i<e.obj.getPath().length; i++){
                    vm.ampArray.push(e.obj.getPath()[i].lng, e.obj.getPath()[i].lat)
                  }
                }
                console.log(vm.ampArray)
                break;
              }
              case 'circle':{
                // console.log('circle')
                vm.ampArray = []
                vm.ampArray.push(e.obj.getCenter().lng)
                vm.ampArray.push(e.obj.getCenter().lat)
                vm.ampArray.push(e.obj.getRadius())
                console.log(vm.ampArray)
                break;
              }
            }
          })
        })
      },
      /**
       * 显示预设区域查询
       */
      showHistorySearch(){
        this.clear();
        this.ampArray = []
        this.regionName = ''
        this.radio = null
        this.mapVisible_3 = true
        this.$nextTick(() => {
            this.$search(this.dialogTable)
        });
      },
      async addRegion(){
        if(this.radio == null){
          this.$message.error('请选择区域类型');
          return
        }
        if(this.regionName == ''){
          this.$message.error('请输入区域名称');
          return
        }
        if(this.ampArray.length == 0){
          this.$message.error('请选择一个区域');
          return
        }
        /**新增预设区域 */
        await this.$api.insertRegionInfo({
          insertType: 0,
          regionInfoVOIOV: {
            regionName: this.regionName,
            regionArgs: this.ampArray,
            regionType: this.radio === 0 ? '矩形' : '圆形',
            regionBelong: 0
          }
        })
        this.$notify({
          title: '成功',
          message: '新增成功',
          type: 'success'
        });

        /**查询预设区域table */
        await this.$search(this.dialogTable)
      },
      /**
       * 查询单个区域并回显图形以及车辆信息
       */
      async searchRegion(val){
        // 情况地图所有覆盖物
        this.map_1.clearMap()
        console.log('--查询单个区域并回显图形以及车辆信息')
        this.mapVisible_3 = false
        let RegionData = {}
        RegionData = await this.$api.searchCarByDefaultRegion({
          regionId: val.regionId,
        })

        this.setTree(RegionData.data)
        this.echoRegion(RegionData)
        this.mapPosition = RegionData.data
        this.$refs.carInfoPopover.isShow = true
      },
      /**
       * 设置车辆tree选中状态
       */
      setTree(data){

        if(!data.length > 0){
          this.$notify.success({
            title: '查询成功',
            message: '该区域无车辆信息'
          })
        }

        let arr_1 = []
        let arr_2 = []
        let arr_3 = []
        for(let y = 0; y < data.length; y++){
          arr_1.push(data[y].id)
          arr_2.push(data[y].position)
          arr_3.push(data[y].deviceStatus)
        }

        this.echoTree(arr_1)
        this.showMarkerInfo(data, arr_2, arr_3)
        this.checkStatus = true
        this.checked()
        
      },
      /**
       * 回显图形
       */
      echoRegion(RegionData){
        let type = RegionData.regionType
        let path= []
        let amapArr = []
        amapArr = RegionData.region
        for(let index=0;index<amapArr.length;index++){
            if((index+1)%2 === 0) continue
            path.push([amapArr[index],amapArr[index+1]]);
        }
        this.clear()
        this.addMapDialogVisible = false
        console.log(JSON.stringify(path))
        switch(type){
          case 0: {
            // 矩形
            console.log("绘制矩形：" + JSON.stringify(amapArr))
            console.log("绘制矩形：" + JSON.stringify(path))
            // 逻辑存在小bug ，如何判定西南、东北坐标点！  逆时针方向绘制，或者坐标的大小判定待定
            var southWest = new AMap.LngLat(amapArr[amapArr.length-4], amapArr[amapArr.length-3]);
            var northEast = new AMap.LngLat(amapArr[0], amapArr[1]);
            var bounds = null;
            if(amapArr[amapArr.length-4]+amapArr[amapArr.length-3]>amapArr[0]+amapArr[1]){
                bounds = new AMap.Bounds(southWest,northEast);
                console.log("右方向绘制!");
            }else{
                console.log("左方向绘制!");
                bounds = new AMap.Bounds(northEast,southWest);
            }
            var rectangle = new AMap.Rectangle({
              bounds: bounds,
              strokeColor: "#80d8ff",
              strokeWeight: 1,
              strokeOpacity:0.5,
              fillColor: '#00b0ff',
              fillOpacity:0.5,
              cursor:'pointer',
              zIndex:50,
          })
          rectangle.setMap(this.map_1)
          // 缩放地图到合适的视野级别
          this.map_1.setFitView([ rectangle ])
            break;
          }
          case 1: {
            // 圆形
            console.log("绘制圆形：" + path[0] + " --半径： " + path[1][0])
            this.circle = new AMap.Circle({
            // center: new AMap.LngLat("116.403322", "39.920255"), // 圆心位置
            center: new AMap.LngLat(path[0][0],path[0][1]),
            radius: path[1][0], //半径
            borderWeight: 1,
            strokeColor: "#80d8ff",
            strokeOpacity: 1,
            strokeWeight: 1,
            fillOpacity: 0.4,
            fillColor: '#00b0ff',
            zIndex: 50,
            })
            this.circle.setMap(this.map_1)
            // 缩放地图到合适的视野级别
            this.map_1.setFitView([this.circle ])

          }
          break;
        }
      },
      /**
       * 删除预设区域单个数据
       */
      async deleteRegion(val){
        console.log('val: ' + JSON.stringify(val))
        await this.$confirm(`您确认删除【${val.regionType}】吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        // 删除预设区域
        await this.$api.DeleteRegionInfo({
          regionIdList: [val.regionId]
        })
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        });
        await this.$search(this.dialogTable)
      },
      /** ---------------------预设区域查询的方法-------------------end--- */
    },
    async mounted () {
      await this.$api.searchByLocationAndTime({})
      this.initAmap()
      this.initAddAreaAmap()
      this.initRegionAmap()
      this.locationCarData = await this.$api.getRealTimeCarInfoList({})
      await this.$search(this.table)
      // await this.$search(this.resultTable)
      // 导出
      this.$api.GetExcelExportKeyAndColumn({
        excelKey: "listCarByTimeAndRegionExport"
      }).then((res)=>{
        this.checkList = res.chsList
        this.checkIndex = this.checkList.map(e=>{
          return e.index
        })
      })
      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.timer);
        clearInterval(this.OtherTimer);
      })
    },
    destroyed(){
      // this.map_1 !== null ? this.map_1.destroy() : this.map_1 === null
      // this.map_2 !== null ? this.map_2.destroy() : this.map_2 === null
      // this.map_3 !== null ? this.map_3.destroy() : this.map_3 === null
    }
  }
</script>

<style lang='sass' scoped>
  .location-content
    height: 100%
    width: 100%
    .common
      display: inline-block
      height: 100%
      vertical-align: top
    .main-map
      width: 100%
    .area-tab
      left: 18px
      top: 23px
      position: absolute
      display: inline-block !important
      z-index: 1
    .area-search
      height: 0px !important
      border-radius: 2px
      &-car
        font-size: 12px
        padding: 7px 18px
        border-radius: 2px
        background: rgba(255,255,255,1)
        box-shadow: 0px 1px 5px 0px rgba(8,88,169,0.3)
        font-family: Microsoft YaHei
        font-weight: 400
        color: rgba(102,102,102,1)
    .lookfor-car-main-table
      display: inline-block
      padding: 16px 14px
      width: calc(100% - 14px)
      height: 100%
      background: #fff
    .result-title
      padding: 16px 0px
    .sp1
      color: #292929
      font-size: 16px
      display: inline-block
      float: left
    .sp2
      color: #0575E6
      font-size: 14px
      display: inline-block
      float: right
    .addArea
      width: 100%
      min-width: 802px
      height: 32px
      border: 1px dotted rgba(217,217,217,1)
      border-radius: 2px
      padding-top: 6px
      margin-top: 14px
      text-align: center
      &:hover
              border: 0.5px dotted rgba(5,117,230,1)
      .a
        font-size: 10px
        color: #666666
        margin-right: 10px
        &:hover
              color: rgba(5,117,230,1)
      .d
        font-size: 12px
        color: #666666
        &:hover
              color: rgba(5,117,230,1)
  .main-box-shadow
    box-shadow: 0 1px 6px 0 rgba(5,117,230,.2)
    border-radius: 2px
  .main-right-list
    float: left
    display: inline-block
    width: 28%
  .main-right-map
    float: left
    display: inline-block
    width: calc(100% - 28%)
  .main-map-pop
    width: 329px
    position: relative
    float: right
  .map-popover
    position: absolute
    z-index: 2
    top: 12px
    right: 12px

    /deep/ .el-tree-node__label
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
    /deep/ .el-dialog__header
      padding: 10px
    /deep/ .el-dialog--center .el-dialog__body
      text-align: initial
      padding: 0px 10px 10px 10px
    /deep/ .table-view-edit
      padding-left: 20px
</style>
